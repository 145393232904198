































































import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "appointment-table-view",
  data() {
    return {
      search: "",
      type: "",
      headers: [
        // {
        //   text: 'Dessert (100g serving)',
        //   align: 'start',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Description", value: "name", sortable: false },
        { text: "Shedule", value: "start", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "Patient", value: "patient", sortable: false },
        { text: "Doctor", value: "doctor", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      appointmetsTypes: [
        {
          label: "Show all",
          value: "",
        },
        {
          label: "Treatment",
          value: "TREATMENT",
        },
        {
          label: "Surgery",
          value: "SURGERY",
        },
        {
          label: "Evaluation",
          value: "EVALUATION",
        },
        {
          label: "Follow Up",
          value: "FOLLOWUP",
        },
      ],
    };
  },
  computed: {
    ...mapState("crmAppointmentsModule", ["loading", "appointments"]),
    filteredAppointments() {
      return (this as any).appointments.filter((appointment: any) => {
        if ((this as any).type == "") return true;
        return appointment.data.type == (this as any).type;
      });
    },
  },
  mounted() {
    (this as any).actListAppointment();
  },
  methods: {
    ...mapActions("crmAppointmentsModule", ["actListAppointment"]),
    formatShedule(date: string): string {
      return new Date(date).toLocaleString().split(" ")[1].slice(0, 5);
    },
    getDate(value: Date) {
      return value.toLocaleString().split(" ")[0].replaceAll("/", "-");
    },
    searchFilter(value: string, search: string, item: any) {
      return value != "" && search != "" && value.includes(search);
    },
  },
});
